import axios from 'axios'

const baseApiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8961' : process.env.VUE_APP_API_URL

const http = axios.create({
  responseType: 'json',
  baseURL: baseApiUrl
});

export default http;
