<template>
  <div class="row">
    <div class="col mb-2 mt-2">
      <Button 
        label="Adicionar FII"
        icon="pi pi-plus"
        iconPos="right"
        @click="AdicionarFII"
        class="p-button-sm" />
    </div>
  </div>
  <TabelaMinhaLista></TabelaMinhaLista>
</template>

<script setup>
import { reactive, onMounted } from 'vue'
import TabelaMinhaLista from '@/components/TabelaMinhaLista.vue'
import Swal from 'sweetalert2'
import { useMainStore } from '@/store/index.js'

const state = reactive({ ocupado: true })
const store = useMainStore()

onMounted(async () => {
  
  state.ocupado = false
})

async function AdicionarFII () {
  const { value: symbol } = await Swal.fire({
    title: 'Adicionar FII a Minha Lista',
    input: 'text',
    inputPlaceholder: 'Informe o FII'
  })

  if (symbol) {
    HandleAdicionar(symbol)
  }  
}

function HandleAdicionar (symbol) {
  store.AtualizarMinhaLista(symbol)
}
</script>

<style lang="css" scoped>
</style>
